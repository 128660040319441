import {ImagePreview} from "./ImagePreview";
import React, {useEffect, useState} from "react";
import frame01 from "./frame01.svg";
import frame02 from "./frame02.svg";
import {imageFromUrl} from "./utils";
import {
    Box,
    Button,
    Card,
    CardBody,
    FormControl,
    FormLabel,
    HStack,
    Image,
    Select,
    SimpleGrid,
    VStack
} from "@chakra-ui/react";
import * as PropTypes from "prop-types";

function HBox(props) {
    return null;
}

HBox.propTypes = {children: PropTypes.node};

export function ImageEditor({image, onCloseClick}) {
    const [scale, setScale] = useState(1)
    const [frames, setFrames] = useState([]);
    const [selectedFrame, setSelectedFrame] = useState('')
    const [dataUrl, setDataUrl] = useState(null)

    useEffect(() => {
        Promise.all([imageFromUrl(frame01), imageFromUrl(frame02)])
            .then(imgs => {
                setFrames(imgs)
                setSelectedFrame(imgs[0])
            })
    }, []);

    function onFrameChange(e) {
        setSelectedFrame(frames[e.target.value])
    }

    function onDownloadClick() {
        const link = document.createElement('a')
        link.href = dataUrl
        link.download = 'profile-photo.png'
        link.click()
    }

    function zoomIn() {
        setScale(scale + 0.2);
    }

    function zoomOut() {
        setScale(scale - 0.2);
    }

    function zoomReset() {
        setScale(1);
    }

    const canZoomIn = scale < 4;
    const canZoomOut = scale > 1;

    return <>
        <Box position='relative'>
            <Button variant='ghost' size='sm' padding='0' position='absolute' top='0px' right='-40px' onClick={onCloseClick} title="Remove">&times;</Button>
            <ImagePreview image={image} scale={scale} frame={selectedFrame} onDataUrlChange={setDataUrl}/>
        </Box>
        <HStack justify='center' mt="20px">
            <Button size='md'  onClick={zoomIn} isDisabled={!canZoomIn}>+</Button>
            <Button size='md'  onClick={zoomOut} isDisabled={!canZoomOut}>-</Button>
        </HStack>
        <SimpleGrid mt='40px' columns={2} spacing={10}>
            <Button onClick={() => setSelectedFrame(frames[0])} h='auto' borderRadius='50%' padding='10px'><Image src={frame01} h="150px" w="150px" p="5px"/></Button>
            <Button onClick={() => setSelectedFrame(frames[1])} h='auto' borderRadius='50%' padding='10px'><Image src={frame02} h="150px" w="150px" p="5px"/></Button>
        </SimpleGrid>
        <Card mt='50px'>
            <CardBody>
                <Button size='lg' colorScheme='whatsapp' onClick={onDownloadClick}>Download</Button>
            </CardBody>
        </Card>
    </>
}
import {useCallback, useState} from "react";
import './FileUploader.css'
import {Card, CardBody, Spinner, VStack} from "@chakra-ui/react";

const STATE = {
    WAITING_FOR_FILE: 'WAITING_FOR_FILE',
    SELECTING_FILE: 'SELECTING_FILE',
    FILE_SELECTED: 'FILE_SELECTED'
}

export function FileUploader({onFileSelected}) {
    const [state, setState] = useState(STATE.WAITING_FOR_FILE);
    const [selectedFile, setSelectedFile] = useState(null)

    const inputCallbackRef = useCallback(input => {
        if (input !== null) {
            input.addEventListener('cancel', handleCancel)
        }
    }, [])

    function handleInput(e) {
        const file = e.target.files[0] ?? null;
        const state = file ? STATE.FILE_SELECTED : STATE.WAITING_FOR_FILE;

        setSelectedFile(file);
        setState(state);

        onFileSelected(file);
    }

    function handleClick() {
        setState(STATE.SELECTING_FILE);
    }

    function handleCancel() {
        const state = selectedFile ? STATE.FILE_SELECTED : STATE.WAITING_FOR_FILE;

        setState(state);
    }

    let message;

    if (state === STATE.WAITING_FOR_FILE) {
        message = 'Click to add a photo';
    } else if (state === STATE.SELECTING_FILE) {
        message = <VStack>
            <Spinner size="xl"
                     thickness="4px"
                     emptyColor="gray.200"
                     color="red.500"
                     speed="0.55s"/>
        </VStack>
    }

    return (
        <Card w="256px" h="256px">
            <CardBody>
                <label
                    id="dropzone"
                    tabIndex="0"
                    role="button">
                    <input type="file"
                           ref={inputCallbackRef}
                           accept="image/*"
                           tabIndex="-1"
                           onClick={handleClick}
                           onInput={handleInput}/>
                    <span>{message}</span>
                </label>
            </CardBody>
        </Card>
    );
}
import './App.css';
import {FileUploader} from "./FileUploader";
import React, {useState} from "react";
import {Box, ChakraProvider, Container, Text} from "@chakra-ui/react";
import {theme} from "./Theme";
import {ImageEditor} from "./ImageEditor";
import {imageFromFile} from "./utils";


function App() {
    const [image, setImage] = useState(null);

    async function onFileSelected(file) {
        let newImage;

        try {
            newImage = await imageFromFile(file);
        } catch {
            newImage = null;
        }

        setImage(newImage);
    }

    function onReset() {
        setImage(null)
    }

    let content;

    if (image) {
        content = <ImageEditor image={image} onCloseClick={onReset}/>
    } else {
        content = <FileUploader onFileSelected={onFileSelected}></FileUploader>
    }

    return (
        <ChakraProvider theme={theme}>
            <Container centerContent paddingTop={20}>
                {content}
                <Box fontSize='xs'
                     color='gray.500'
                     maxW='300px'
                     textAlign='center' mt='8'>
                    <Text mb={{base: 2, sm: 0}}>
                        Stworzone przez MONOCODE Paweł Smoleński dla Technologie Diabetyka na licencji MIT.
                    </Text>
                </Box>
            </Container>

        </ChakraProvider>
    )
}


export default App;
